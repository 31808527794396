<template>
	<div>
		<div class="userInformation" :style="'background-image:' + `url(${img})`">
			<div class="ui_titleDiv">
				<div class="ui_td_text">{{$t('pcbaMade.userInformation.title')}}</div>
			</div>
			
			<div class="ui_contentDiv">
				<div class="ui_cd_div">
					<div class="ui_cd_di_titleDiv">
						<div class="ui_cd_di_td_icon">√</div>
						<div class="ui_cd_di_td_text">{{$t('pcbaMade.userInformation.content1.title')}}</div>
					</div>
					<div class="ui_cd_di_td_contentDiv">
						<div class="ui_cd_di_td_cd_content">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="requiredText">{{$t('pcbaMade.userInformation.content1.text1')}}<span style="color: red;">{{$t('pcbaMade.userInformation.content1.prompt')}}</span></div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content1.text2')}}</div>
							</div>
						</div>
			
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="requiredText">{{$t('pcbaMade.userInformation.content1.text3')}}<span style="color: red;">{{$t('pcbaMade.userInformation.content1.prompt')}}</span></div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content1.text4')}}</div>
							</div>
						</div>
			
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content1.text5')}}</div>
							</div>
						</div>
			
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content1.text6')}}</div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content1.text7')}}</div>
							</div>
						</div>
			
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content1.text8')}}</div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content1.text9')}}</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="ui_cd_div">
					<div class="ui_cd_di_titleDiv">
						<div class="ui_cd_di_td_icon">√</div>
						<div class="ui_cd_di_td_text">{{$t('pcbaMade.userInformation.content2.title')}}</div>
					</div>
					<div class="ui_cd_di_td_contentDiv">
						<div class="ui_cd_di_td_cd_content">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text1')}}</div>
							</div>
						</div>
							
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text2')}}</div>
							</div>
						</div>
							
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text3')}}</div>
							</div>
						</div>
							
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text4')}}</div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text5')}}</div>
							</div>
						</div>
							
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text6')}}</div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text7')}}</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="ui_cd_div">
					<div class="ui_cd_di_titleDiv">
						<div class="ui_cd_di_td_icon">√</div>
						<div class="ui_cd_di_td_text">{{$t('pcbaMade.userInformation.content3.title')}}</div>
					</div>
					<div class="ui_cd_di_td_contentDiv">
						<div class="ui_cd_di_td_cd_content">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text1')}}</div>
							</div>
						</div>
							
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text2')}}</div>
							</div>
						</div>
							
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text3')}}</div>
							</div>
						</div>
							
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text4')}}</div>
							</div>
						</div>
							
						<div class="ui_cd_di_td_cd_content" style="padding-top: 10px;">
							<div class="ui_cd_di_td_cd_ct_icon">√</div>
							<div>
								<div class="textContent">{{$t('pcbaMade.userInformation.content2.text5')}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				img:process.env.VUE_APP_OSS_URL + '/manufacture/patch-weld-bg.jpg',
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.userInformation {
			padding: 20px 200px;
		
			.ui_titleDiv {
				text-align: center;
		
				.ui_td_text {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
			}
		
			.ui_contentDiv {
				position: relative;
				display: flex;
				width: 100%;
		
				.ui_cd_div {
					width: 33%;
					margin-left: 1%;
		
					.ui_cd_di_titleDiv {
						display: flex;
						align-items: center;
						padding: 50px 20px;
						background: #333333;
		
						.ui_cd_di_td_icon {
							color: #333333;
							padding: 5px 10px;
							background: red;
							border-radius: 40px;
						}
		
						.ui_cd_di_td_text {
							padding-left: 10px;
							font-size: 18px;
							color: #fff;
						}
					}
		
					.ui_cd_di_td_contentDiv {
						padding: 40px 20px;
						background: #fff;
						height: 600px;
		
						.ui_cd_di_td_cd_content {
							display: flex;
							align-items: center;
		
							.ui_cd_di_td_cd_ct_icon {
								color: #fff;
								padding: 2px 6px;
								background: #bfbfbf;
								border-radius: 40px;
								font-size: 2px;
								margin-right: 10px;
							}
							
							.requiredText {
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
								display: flex;
							}
							
							.textContent {
								line-height: 30px;
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.userInformation {
			padding: 20px 200px;
		
			.ui_titleDiv {
				text-align: center;
		
				.ui_td_text {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
			}
		
			.ui_contentDiv {
				position: relative;
				display: flex;
				width: 100%;
		
				.ui_cd_div {
					width: 33%;
					margin-left: 1%;
		
					.ui_cd_di_titleDiv {
						display: flex;
						align-items: center;
						padding: 50px 20px;
						background: #333333;
		
						.ui_cd_di_td_icon {
							color: #333333;
							padding: 5px 10px;
							background: red;
							border-radius: 40px;
						}
		
						.ui_cd_di_td_text {
							padding-left: 10px;
							font-size: 18px;
							color: #fff;
						}
					}
		
					.ui_cd_di_td_contentDiv {
						padding: 40px 20px;
						background: #fff;
						height: 600px;
		
						.ui_cd_di_td_cd_content {
							display: flex;
							align-items: center;
		
							.ui_cd_di_td_cd_ct_icon {
								color: #fff;
								padding: 2px 6px;
								background: #bfbfbf;
								border-radius: 40px;
								font-size: 2px;
								margin-right: 10px;
							}
							
							.requiredText {
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
								display: flex;
							}
							
							.textContent {
								line-height: 30px;
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.userInformation {
			padding: 20px 140px;
		
			.ui_titleDiv {
				text-align: center;
		
				.ui_td_text {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
			}
		
			.ui_contentDiv {
				position: relative;
				display: flex;
				width: 100%;
		
				.ui_cd_div {
					width: 33%;
					margin-left: 1%;
		
					.ui_cd_di_titleDiv {
						display: flex;
						align-items: center;
						padding: 50px 20px;
						background: #333333;
		
						.ui_cd_di_td_icon {
							color: #333333;
							padding: 5px 10px;
							background: red;
							border-radius: 40px;
						}
		
						.ui_cd_di_td_text {
							padding-left: 10px;
							font-size: 18px;
							color: #fff;
						}
					}
		
					.ui_cd_di_td_contentDiv {
						padding: 40px 20px;
						background: #fff;
						height: 600px;
		
						.ui_cd_di_td_cd_content {
							display: flex;
							align-items: center;
		
							.ui_cd_di_td_cd_ct_icon {
								color: #fff;
								padding: 2px 6px;
								background: #bfbfbf;
								border-radius: 40px;
								font-size: 2px;
								margin-right: 10px;
							}
							
							.requiredText {
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
								display: flex;
							}
							
							.textContent {
								line-height: 30px;
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.userInformation {
			padding: 20px 30px;
		
			.ui_titleDiv {
				text-align: center;
		
				.ui_td_text {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
			}
		
			.ui_contentDiv {
				position: relative;
				display: flex;
				width: 100%;
		
				.ui_cd_div {
					width: 33%;
					margin-left: 1%;
		
					.ui_cd_di_titleDiv {
						display: flex;
						align-items: center;
						padding: 50px 20px;
						background: #333333;
		
						.ui_cd_di_td_icon {
							color: #333333;
							padding: 5px 10px;
							background: red;
							border-radius: 40px;
						}
		
						.ui_cd_di_td_text {
							padding-left: 10px;
							font-size: 18px;
							color: #fff;
						}
					}
		
					.ui_cd_di_td_contentDiv {
						padding: 40px 20px;
						background: #fff;
						height: 600px;
		
						.ui_cd_di_td_cd_content {
							display: flex;
							align-items: center;
		
							.ui_cd_di_td_cd_ct_icon {
								color: #fff;
								padding: 2px 6px;
								background: #bfbfbf;
								border-radius: 40px;
								font-size: 2px;
								margin-right: 10px;
							}
							
							.requiredText {
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
								display: flex;
							}
							
							.textContent {
								line-height: 30px;
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.userInformation {
			padding: 20px 30px;
		
			.ui_titleDiv {
				text-align: center;
		
				.ui_td_text {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
			}
		
			.ui_contentDiv {
				position: relative;
				width: 100%;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				margin-top: 20px;
		
				.ui_cd_div {
					width: 49%;
					margin-left: 1%;
					margin-bottom: 20px;
		
					.ui_cd_di_titleDiv {
						display: flex;
						align-items: center;
						padding: 50px 20px;
						background: #333333;
		
						.ui_cd_di_td_icon {
							color: #333333;
							padding: 5px 10px;
							background: red;
							border-radius: 40px;
						}
		
						.ui_cd_di_td_text {
							padding-left: 10px;
							font-size: 18px;
							color: #fff;
						}
					}
		
					.ui_cd_di_td_contentDiv {
						padding: 40px 20px;
						background: #fff;
						height: 600px;
		
						.ui_cd_di_td_cd_content {
							display: flex;
							align-items: center;
		
							.ui_cd_di_td_cd_ct_icon {
								color: #fff;
								padding: 2px 6px;
								background: #bfbfbf;
								border-radius: 40px;
								font-size: 2px;
								margin-right: 10px;
							}
							
							.requiredText {
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
								display: flex;
							}
							
							.textContent {
								line-height: 30px;
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.userInformation {
			padding: 20px 30px;
		
			.ui_titleDiv {
				text-align: center;
		
				.ui_td_text {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
			}
		
			.ui_contentDiv {
				position: relative;
				width: 100%;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				margin-top: 20px;
		
				.ui_cd_div {
					width: 100%;
					margin-bottom: 20px;
		
					.ui_cd_di_titleDiv {
						display: flex;
						align-items: center;
						padding: 50px 20px;
						background: #333333;
		
						.ui_cd_di_td_icon {
							color: #333333;
							padding: 5px 10px;
							background: red;
							border-radius: 40px;
						}
		
						.ui_cd_di_td_text {
							padding-left: 10px;
							font-size: 18px;
							color: #fff;
						}
					}
		
					.ui_cd_di_td_contentDiv {
						padding: 40px 20px;
						background: #fff;
						height: 600px;
		
						.ui_cd_di_td_cd_content {
							display: flex;
							align-items: center;
		
							.ui_cd_di_td_cd_ct_icon {
								color: #fff;
								padding: 5px 10px;
								background: #bfbfbf;
								border-radius: 40px;
								font-size: 10px;
								margin-right: 10px;
							}
							
							.requiredText {
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
								display: flex;
							}
							
							.textContent {
								line-height: 30px;
								padding-left: 10px;
								font-size: 16px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
	
</style>
