<template>
	<div>
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
		<right v-if="windowWidth>=1200"></right>
		<banner :windowWidth="windowWidth" :windowHeight="windowHeight"></banner>
		<pcba-assemble :windowWidth="windowWidth" :windowHeight="windowHeight"></pcba-assemble>
		<smt-patch :windowWidth="windowWidth" :windowHeight="windowHeight"></smt-patch>
		<process-capability :windowWidth="windowWidth" :windowHeight="windowHeight"></process-capability>
		<user-information :windowWidth="windowWidth" :windowHeight="windowHeight"></user-information>
		<bottom v-if="windowWidth>=1200"></bottom>
	</div>
</template>

<script>
	import top from '@/components/top/index.vue';
	import right from '@/components/right/index.vue';
	import banner from './components/pcbaMade/banner.vue';
	import pcbaAssemble from './components/pcbaMade/pcbaAssemble.vue';
	import smtPatch from './components/pcbaMade/smtPatch.vue';
	import processCapability from './components/pcbaMade/processCapability.vue';
	import userInformation from './components/pcbaMade/userInformation.vue';
	import bottom from '@/components/bottom/index.vue';
	export default {
		components:{
			top,
			banner,
			pcbaAssemble,
			smtPatch,
			processCapability,
			userInformation,
			bottom,
			right
		},
		data() {
			return {
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
</style>