<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="processCapability">
				<div class="pc_titleDiv">
					<div class="pc_td_title">{{$t('pcbaMade.processCapability.title')}}</div>
					<div class="pc_td_text">{{$t('pcbaMade.processCapability.text')}}</div>
				</div>
			</div>
			
			<div class="pc_td_contentDiv" :style="'background-image:' + `url(${$t('pcbaMade.processCapability.img1')})`">
				<div class="pc_td_cd_div">
					<div class="pc_td_cd_div_contentDiv">
						<div class="pc_td_cd_div_cd_list" v-for="(item,index) in $t('pcbaMade.processCapability.list')" :key="index">
							<div class="pc_td_cd_div_cd_li_name">{{item.name}}</div>
							<div class="pc_td_cd_div_cd_li_content" v-for="(item2,index2) in item.text" :key="index2">
								{{item2}}
							</div>
						</div>
					</div>
					<el-image class="pc_td_cd_div_img" :src="$t('pcbaMade.processCapability.img2')" :fit="'cover'"></el-image>
				</div>
			</div>
		</div>
		
		<div v-if="windowWidth<=1199">
			<div class="processCapability2">
				<div class="pc_titleDiv">
					<div class="pc_td_title">{{$t('pcbaMade.processCapability.title')}}</div>
				</div>
			</div>
			
			<div class="pc_td_contentDiv2" :style="'background-image:' + `url(${$t('pcbaMade.processCapability.img1')})`">
				<div class="pc_td_cd_div">
					<div class="pc_td_cd_div_contentDiv">
						<div class="pc_td_cd_div_cd_list" v-for="(item,index) in $t('pcbaMade.processCapability.list')" :key="index">
							<div class="pc_td_cd_div_cd_li_name">{{item.name}}</div>
							<div class="pc_td_cd_div_cd_li_content" v-for="(item2,index2) in item.text" :key="index2">
								{{item2}}
							</div>
						</div>
					</div>
					<el-image class="pc_td_cd_div_img" :src="$t('pcbaMade.processCapability.img2')" :fit="'cover'"></el-image>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data(){
			return{
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		},
	}
</script>

<style lang="less">
	
	@media only screen and (min-width:1920px) {
		.processCapability{
			padding: 40px 200px;
			
			.pc_titleDiv{
				text-align: center;
				position: relative;
				
				.pc_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
				
				.pc_td_text{
					font-size: 40px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		}
		
		.pc_td_contentDiv{
			padding: 40px 200px;
			
			.pc_td_cd_div{
				background: #fff;
				padding: 20px;
				border-right:5px solid red;
				border-bottom: 5px solid red;
				position: relative;
				
				.pc_td_cd_div_contentDiv{
					box-sizing: border-box;
					display: flex;
					width: 100%;
					flex-wrap: wrap;
					
					.pc_td_cd_div_cd_list{
						width: 24%;
						margin-bottom: 1vw;
						
						.pc_td_cd_div_cd_li_name{
							font-weight: bold;
							font-size: 1vw;
							color: red;
							line-height: 30px;
						}
						
						.pc_td_cd_div_cd_li_content{
							color: #333333;
							line-height: 30px;
						}
					}
				}
				
				.pc_td_cd_div_img{
					width: 10vw;
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.processCapability{
			padding: 40px 200px;
			
			.pc_titleDiv{
				text-align: center;
				position: relative;
				
				.pc_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
				
				.pc_td_text{
					font-size: 40px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		}
		
		.pc_td_contentDiv{
			padding: 40px 200px;
			
			.pc_td_cd_div{
				background: #fff;
				padding: 20px;
				border-right:5px solid red;
				border-bottom: 5px solid red;
				position: relative;
				
				.pc_td_cd_div_contentDiv{
					box-sizing: border-box;
					display: flex;
					width: 100%;
					flex-wrap: wrap;
					
					.pc_td_cd_div_cd_list{
						width: 24%;
						margin-bottom: 1vw;
						
						.pc_td_cd_div_cd_li_name{
							font-weight: bold;
							font-size: 1vw;
							color: red;
							line-height: 30px;
						}
						
						.pc_td_cd_div_cd_li_content{
							color: #333333;
							line-height: 30px;
						}
					}
				}
				
				.pc_td_cd_div_img{
					width: 10vw;
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.processCapability{
			padding: 40px 140px;
			
			.pc_titleDiv{
				text-align: center;
				position: relative;
				
				.pc_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
				
				.pc_td_text{
					font-size: 40px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		}
		
		.pc_td_contentDiv{
			padding: 40px 140px;
			
			.pc_td_cd_div{
				background: #fff;
				padding: 20px;
				border-right:5px solid red;
				border-bottom: 5px solid red;
				position: relative;
				
				.pc_td_cd_div_contentDiv{
					box-sizing: border-box;
					display: flex;
					width: 100%;
					flex-wrap: wrap;
					
					.pc_td_cd_div_cd_list{
						width: 24%;
						margin-bottom: 1vw;
						
						.pc_td_cd_div_cd_li_name{
							font-weight: bold;
							font-size: 1vw;
							color: red;
							line-height: 30px;
						}
						
						.pc_td_cd_div_cd_li_content{
							color: #333333;
							line-height: 30px;
						}
					}
				}
				
				.pc_td_cd_div_img{
					width: 10vw;
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.processCapability{
			padding: 40px 30px;
			
			.pc_titleDiv{
				text-align: center;
				position: relative;
				
				.pc_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
				
				.pc_td_text{
					font-size: 40px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		}
		
		.pc_td_contentDiv{
			padding: 40px 30px;
			
			.pc_td_cd_div{
				background: #fff;
				padding: 20px;
				border-right:5px solid red;
				border-bottom: 5px solid red;
				position: relative;
				
				.pc_td_cd_div_contentDiv{
					box-sizing: border-box;
					display: flex;
					width: 100%;
					flex-wrap: wrap;
					
					.pc_td_cd_div_cd_list{
						width: 24%;
						margin-bottom: 1vw;
						
						.pc_td_cd_div_cd_li_name{
							font-weight: bold;
							font-size: 1vw;
							color: red;
							line-height: 30px;
						}
						
						.pc_td_cd_div_cd_li_content{
							color: #333333;
							line-height: 30px;
						}
					}
				}
				
				.pc_td_cd_div_img{
					width: 10vw;
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.processCapability2{
			padding: 40px 30px;
			
			.pc_titleDiv{
				text-align: center;
				position: relative;
				
				.pc_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
				
				.pc_td_text{
					font-size: 40px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		}
		
		.pc_td_contentDiv2{
			padding: 40px 30px;
			
			.pc_td_cd_div{
				background: #fff;
				padding: 20px;
				border-right:5px solid red;
				border-bottom: 5px solid red;
				position: relative;
				
				.pc_td_cd_div_contentDiv{
					box-sizing: border-box;
					display: flex;
					width: 100%;
					flex-wrap: wrap;
					
					.pc_td_cd_div_cd_list{
						width: 33%;
						margin-bottom: 1vw;
						
						.pc_td_cd_div_cd_li_name{
							font-weight: bold;
							font-size: 1vw;
							color: red;
							line-height: 30px;
						}
						
						.pc_td_cd_div_cd_li_content{
							color: #333333;
							line-height: 30px;
						}
					}
				}
				
				.pc_td_cd_div_img{
					width: 10vw;
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.processCapability2{
			padding: 40px 30px;
			
			.pc_titleDiv{
				text-align: center;
				position: relative;
				
				.pc_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
				
				.pc_td_text{
					font-size: 40px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		}
		
		.pc_td_contentDiv2{
			padding: 40px 30px;
			
			.pc_td_cd_div{
				background: #fff;
				padding: 20px;
				border-right:5px solid red;
				border-bottom: 5px solid red;
				position: relative;
				
				.pc_td_cd_div_contentDiv{
					box-sizing: border-box;
					display: flex;
					width: 100%;
					flex-wrap: wrap;
					
					.pc_td_cd_div_cd_list{
						width: 50%;
						margin-bottom: 10px;
						
						.pc_td_cd_div_cd_li_name{
							font-weight: bold;
							font-size: 12px;
							color: red;
							line-height: 30px;
						}
						
						.pc_td_cd_div_cd_li_content{
							color: #333333;
							line-height: 30px;
							font-size: 12px;
						}
					}
				}
				
				.pc_td_cd_div_img{
					width: 30vw;
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}
		}
	}
	
</style>