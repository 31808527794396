<template>
	<div>
		<div class="smtPatch" :style="'background-image:' + `url(${$t('pcbaMade.smtPatch.img')})`" v-if="windowWidth>=1200">
			<div class="sp_titleDiv">
				<div class="sp_td_title">{{$t('pcbaMade.smtPatch.title')}}</div>
				<div class="sp_td_text">{{$t('pcbaMade.smtPatch.text')}}</div>
			</div>
			<div class="sp_contentDiv">
				<div class="sp_cd_div" style="margin-right: 1%;">
					<div class="sp_cd_bgDiv">
						<img class="sp_cd_bd_img" :src="$t('pcbaMade.smtPatch.content1.img')" />
						
						<div class="sp_cd_bd_contentDiv">
							<div class="sp_cd_bd_cd_pathTitle">
								<span class="sp_cd_bd_cd_pt_leftText">{{$t('pcbaMade.smtPatch.content1.title')}}</span>
								<span class="sp_cd_bd_cd_pt_right">→</span>
							</div>
							
							<div class="sp_cd_bd_cd_pathContent">{{$t('pcbaMade.smtPatch.content1.label1')}}</div>
							<div class="sp_cd_bd_cd_pc_text">{{$t('pcbaMade.smtPatch.content1.label2')}}</div>
							<div class="sp_cd_bd_cd_pc_text">{{$t('pcbaMade.smtPatch.content1.label3')}}</div>
						</div>
					</div>
				</div>
				
				<div class="sp_cd_div">
					<div class="sp_cd_bgDiv">
						<img class="sp_cd_bd_img" :src="$t('pcbaMade.smtPatch.content2.img')" />
						
						<div class="sp_cd_bd_contentDiv">
							<div class="sp_cd_bd_cd_pathTitle">
								<span class="sp_cd_bd_cd_pt_leftText">{{$t('pcbaMade.smtPatch.content2.title')}}</span>
								<span class="sp_cd_bd_cd_pt_right">→</span>
							</div>
							
							<div class="sp_cd_bd_cd_pathContent">{{$t('pcbaMade.smtPatch.content2.label1')}}</div>
							<div class="sp_cd_bd_cd_pc_text">{{$t('pcbaMade.smtPatch.content2.label2')}}</div>
							<div class="sp_cd_bd_cd_pc_text">{{$t('pcbaMade.smtPatch.content2.label3')}}</div>
							<div class="sp_cd_bd_cd_pc_text">{{$t('pcbaMade.smtPatch.content2.label4')}}</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="sp_text">{{$t('pcbaMade.smtPatch.text2')}}</div>
		</div>
		
		<div class="smtPatch2" :style="'background-image:' + `url(${$t('pcbaMade.smtPatch.img')})`" v-if="windowWidth<=1199">
			<div class="sp_titleDiv">
				<div class="sp_td_title">{{$t('pcbaMade.smtPatch.title')}}</div>
				<div class="sp_td_text">{{$t('pcbaMade.smtPatch.text')}}</div>
			</div>
			<div class="sp_contentDiv">
				<div class="sp_cd_div" style="margin-right: 1%;">
					<div class="sp_cd_bgDiv">
						<el-image style="width: 100%;height: 100%;" :src="$t('pcbaMade.smtPatch.content1.img')" :fit="'fill'"></el-image>
						
						<div class="sp_cd_bd_contentDiv">
							<div class="sp_cd_bd_cd_pathTitle">
								<span class="sp_cd_bd_cd_pt_leftText">{{$t('pcbaMade.smtPatch.content1.title')}}</span>
								<span class="sp_cd_bd_cd_pt_right">→</span>
							</div>
							
							<div class="sp_cd_bd_cd_pathContent">{{$t('pcbaMade.smtPatch.content1.label1')}}</div>
							<div class="sp_cd_bd_cd_pc_text">{{$t('pcbaMade.smtPatch.content1.label2')}}</div>
							<div class="sp_cd_bd_cd_pc_text">{{$t('pcbaMade.smtPatch.content1.label3')}}</div>
						</div>
					</div>
				</div>
				
				<div class="sp_cd_div">
					<div class="sp_cd_bgDiv">
						<el-image style="width: 100%;height: 100%;" :src="$t('pcbaMade.smtPatch.content2.img')" :fit="'fill'"></el-image>
						
						<div class="sp_cd_bd_contentDiv">
							<div class="sp_cd_bd_cd_pathTitle">
								<span class="sp_cd_bd_cd_pt_leftText">{{$t('pcbaMade.smtPatch.content2.title')}}</span>
								<span class="sp_cd_bd_cd_pt_right">→</span>
							</div>
							
							<div class="sp_cd_bd_cd_pathContent">{{$t('pcbaMade.smtPatch.content2.label1')}}</div>
							<div class="sp_cd_bd_cd_pc_text">{{$t('pcbaMade.smtPatch.content2.label2')}}</div>
							<div class="sp_cd_bd_cd_pc_text">{{$t('pcbaMade.smtPatch.content2.label3')}}</div>
							<div class="sp_cd_bd_cd_pc_text">{{$t('pcbaMade.smtPatch.content2.label4')}}</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="sp_text">{{$t('pcbaMade.smtPatch.text2')}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.smtPatch{
			padding: 40px 200px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
				}
			}
			
			.sp_contentDiv{
				display: flex;
				width: 100%;
				
				.sp_cd_div{
					width: 49%;
					margin-top: 60px;
					
					.sp_cd_bgDiv{
						width: 100%;
						background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.7) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(rgba(241, 60, 58, 0.9), rgba(241, 60, 58, 0.9));
						background-blend-mode: normal, normal;
						padding: 0.6vw;
						display: flex;
						
						.sp_cd_bd_img{
							width: 16vw;
							height: 320px;
							background-size: cover;
						}
						
						.sp_cd_bd_contentDiv{
							padding-left: 20px;
							
							.sp_cd_bd_cd_pathTitle{
								display: block;
								width: 242px;
								height: 38px;
								line-height: 38px;
								background: #fff;
								border-radius: 15px 0 15px 0;
								overflow: hidden;
								
								.sp_cd_bd_cd_pt_leftText{
									font-size: 20px;
									color: red;
									width: 185px;
									display: inline-block;
									padding-left: 20px;
									font-weight: bold;
								}
								
								.sp_cd_bd_cd_pt_right{
									float: right;
									width: 57px;
									height: 38px;
									background: #f2f2f2;
									text-align: center;
									color: red;
								}
							}
							
							.sp_cd_bd_cd_pathContent{
								font-size: 16px;
								color: #fff;
								line-height: 1.5vw;
								padding-top: 20px;
							}
							
							.sp_cd_bd_cd_pc_text{
								font-size: 16px;
								color: #fff;
								line-height: 1.5vw;
							}
						}
					}
				}
			}
			
			.sp_text{
				font-size: 18px;
				color: #fff;
				line-height: 1.6vw;
				padding-top: 20px;
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.smtPatch{
			padding: 40px 200px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
				}
			}
			
			.sp_contentDiv{
				display: flex;
				width: 100%;
				
				.sp_cd_div{
					width: 49%;
					margin-top: 60px;
					
					.sp_cd_bgDiv{
						width: 100%;
						background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.7) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(rgba(241, 60, 58, 0.9), rgba(241, 60, 58, 0.9));
						background-blend-mode: normal, normal;
						padding: 0.6vw;
						display: flex;
						
						.sp_cd_bd_img{
							width: 16vw;
							height: 320px;
							background-size: cover;
						}
						
						.sp_cd_bd_contentDiv{
							padding-left: 20px;
							
							.sp_cd_bd_cd_pathTitle{
								display: block;
								width: 242px;
								height: 38px;
								line-height: 38px;
								background: #fff;
								border-radius: 15px 0 15px 0;
								overflow: hidden;
								
								.sp_cd_bd_cd_pt_leftText{
									font-size: 20px;
									color: red;
									width: 185px;
									display: inline-block;
									padding-left: 20px;
									font-weight: bold;
								}
								
								.sp_cd_bd_cd_pt_right{
									float: right;
									width: 57px;
									height: 38px;
									background: #f2f2f2;
									text-align: center;
									color: red;
								}
							}
							
							.sp_cd_bd_cd_pathContent{
								font-size: 16px;
								color: #fff;
								line-height: 1.5vw;
								padding-top: 20px;
							}
							
							.sp_cd_bd_cd_pc_text{
								font-size: 16px;
								color: #fff;
								line-height: 1.5vw;
							}
						}
					}
				}
			}
			
			.sp_text{
				font-size: 18px;
				color: #fff;
				line-height: 1.6vw;
				padding-top: 20px;
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.smtPatch{
			padding: 40px 140px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
				}
			}
			
			.sp_contentDiv{
				display: flex;
				width: 100%;
				
				.sp_cd_div{
					width: 49%;
					margin-top: 60px;
					
					.sp_cd_bgDiv{
						width: 100%;
						background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.7) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(rgba(241, 60, 58, 0.9), rgba(241, 60, 58, 0.9));
						background-blend-mode: normal, normal;
						padding: 0.6vw;
						display: flex;
						
						.sp_cd_bd_img{
							width: 16vw;
							height: 320px;
							background-size: cover;
						}
						
						.sp_cd_bd_contentDiv{
							padding-left: 20px;
							
							.sp_cd_bd_cd_pathTitle{
								display: block;
								width: 242px;
								height: 38px;
								line-height: 38px;
								background: #fff;
								border-radius: 15px 0 15px 0;
								overflow: hidden;
								
								.sp_cd_bd_cd_pt_leftText{
									font-size: 20px;
									color: red;
									width: 185px;
									display: inline-block;
									padding-left: 20px;
									font-weight: bold;
								}
								
								.sp_cd_bd_cd_pt_right{
									float: right;
									width: 57px;
									height: 38px;
									background: #f2f2f2;
									text-align: center;
									color: red;
								}
							}
							
							.sp_cd_bd_cd_pathContent{
								font-size: 16px;
								color: #fff;
								line-height: 1.5vw;
								padding-top: 20px;
							}
							
							.sp_cd_bd_cd_pc_text{
								font-size: 16px;
								color: #fff;
								line-height: 1.5vw;
							}
						}
					}
				}
			}
			
			.sp_text{
				font-size: 18px;
				color: #fff;
				line-height: 1.6vw;
				padding-top: 20px;
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.smtPatch{
			padding: 40px 30px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.sp_td_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
				}
			}
			
			.sp_contentDiv{
				display: flex;
				width: 100%;
				
				.sp_cd_div{
					width: 49%;
					margin-top: 60px;
					
					.sp_cd_bgDiv{
						width: 100%;
						background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.7) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(rgba(241, 60, 58, 0.9), rgba(241, 60, 58, 0.9));
						background-blend-mode: normal, normal;
						padding: 0.6vw;
						display: flex;
						
						.sp_cd_bd_img{
							width: 16vw;
							height: 320px;
							background-size: cover;
						}
						
						.sp_cd_bd_contentDiv{
							padding-left: 20px;
							
							.sp_cd_bd_cd_pathTitle{
								display: block;
								width: 242px;
								height: 38px;
								line-height: 38px;
								background: #fff;
								border-radius: 15px 0 15px 0;
								overflow: hidden;
								
								.sp_cd_bd_cd_pt_leftText{
									font-size: 20px;
									color: red;
									width: 185px;
									display: inline-block;
									padding-left: 20px;
									font-weight: bold;
								}
								
								.sp_cd_bd_cd_pt_right{
									float: right;
									width: 57px;
									height: 38px;
									background: #f2f2f2;
									text-align: center;
									color: red;
								}
							}
							
							.sp_cd_bd_cd_pathContent{
								font-size: 16px;
								color: #fff;
								line-height: 1.5vw;
								padding-top: 20px;
							}
							
							.sp_cd_bd_cd_pc_text{
								font-size: 16px;
								color: #fff;
								line-height: 1.5vw;
							}
						}
					}
				}
			}
			
			.sp_text{
				font-size: 18px;
				color: #fff;
				line-height: 1.6vw;
				padding-top: 20px;
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.smtPatch2{
			padding: 40px 30px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.sp_td_text{
					font-size: 16px;
					color: #fff;
					line-height: 28px;
				}
			}
			
			.sp_contentDiv{
				width: 100%;
				
				.sp_cd_div{
					width: 100%;
					margin-top: 60px;
					
					.sp_cd_bgDiv{
						width: 100%;
						background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.7) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(rgba(241, 60, 58, 0.9), rgba(241, 60, 58, 0.9));
						background-blend-mode: normal, normal;
						padding: 20px;
						
						.sp_cd_bd_img{
						}
						
						.sp_cd_bd_contentDiv{
							
							.sp_cd_bd_cd_pathTitle{
								margin-top: 20px;
								display: block;
								height: 38px;
								width: 242px;
								line-height: 38px;
								background: #fff;
								border-radius: 15px 0 15px 0;
								overflow: hidden;
								
								.sp_cd_bd_cd_pt_leftText{
									font-size: 20px;
									color: red;
									width: 185px;
									display: inline-block;
									padding-left: 20px;
									font-weight: bold;
								}
								
								.sp_cd_bd_cd_pt_right{
									float: right;
									width: 57px;
									height: 38px;
									background: #f2f2f2;
									text-align: center;
									color: red;
								}
							}
							
							.sp_cd_bd_cd_pathContent{
								font-size: 16px;
								color: #fff;
								padding-top: 20px;
							}
							
							.sp_cd_bd_cd_pc_text{
								font-size: 16px;
								color: #fff;
							}
						}
					}
				}
			}
			
			.sp_text{
				font-size: 16px;
				color: #fff;
				padding-top: 20px;
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.smtPatch2{
			padding: 40px 30px;
			
			.sp_titleDiv{
				text-align: center;
				
				.sp_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.sp_td_text{
					font-size: 16px;
					color: #fff;
					line-height: 28px;
				}
			}
			
			.sp_contentDiv{
				width: 100%;
				
				.sp_cd_div{
					width: 100%;
					margin-top: 60px;
					
					.sp_cd_bgDiv{
						width: 100%;
						background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.7) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(rgba(241, 60, 58, 0.9), rgba(241, 60, 58, 0.9));
						background-blend-mode: normal, normal;
						padding: 20px;
						
						.sp_cd_bd_img{
						}
						
						.sp_cd_bd_contentDiv{
							
							.sp_cd_bd_cd_pathTitle{
								margin-top: 20px;
								display: block;
								height: 38px;
								width: 242px;
								line-height: 38px;
								background: #fff;
								border-radius: 15px 0 15px 0;
								overflow: hidden;
								
								.sp_cd_bd_cd_pt_leftText{
									font-size: 20px;
									color: red;
									width: 185px;
									display: inline-block;
									padding-left: 20px;
									font-weight: bold;
								}
								
								.sp_cd_bd_cd_pt_right{
									float: right;
									width: 57px;
									height: 38px;
									background: #f2f2f2;
									text-align: center;
									color: red;
								}
							}
							
							.sp_cd_bd_cd_pathContent{
								font-size: 16px;
								color: #fff;
								padding-top: 20px;
							}
							
							.sp_cd_bd_cd_pc_text{
								font-size: 16px;
								color: #fff;
							}
						}
					}
				}
			}
			
			.sp_text{
				font-size: 16px;
				color: #fff;
				padding-top: 20px;
			}
		}
	}
	
</style>