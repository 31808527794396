<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="banner" :style="'background-image:' + `url(${require('@/assets/img/patch-weld-bg.jpg')})`">
				<div class="ba_title">{{$t('pcbaMade.banner.title')}}</div>
				<div class="ba_text">{{$t('pcbaMade.banner.text')}}</div>
				<div class="ba_bannerHot">
					<span class="ba_bh_lable">{{$t('pcbaMade.banner.text2')}}</span>
					<span class="ba_bh_hot">{{$t('pcbaMade.banner.label1')}}</span>
					<span class="ba_bh_hot">{{$t('pcbaMade.banner.label2')}}</span>
					<span class="ba_bh_hot">{{$t('pcbaMade.banner.label3')}}</span>
				</div>
				<!-- <div class="ba_jump">{{$t('pcbaMade.banner.button')}}</div> -->
			</div>
			<div class="card">
				<div class="card_div">
					<img class="card_div_img" src="../../../../assets/img/ver1.png" />
					<div style="">
						<div class="card_div_title">{{$t('pcbaMade.banner.content1.title')}}</div>
						<div class="card_div_text">{{$t('pcbaMade.banner.content1.text')}}</div>
					</div>
				</div>
				
				<div class="card_div">
					<img class="card_div_img" src="../../../../assets/img/ver2.png" />
					<div style="">
						<div class="card_div_title">{{$t('pcbaMade.banner.content2.title')}}</div>
						<div class="card_div_text">{{$t('pcbaMade.banner.content2.text')}}</div>
					</div>
				</div>
				
				<div class="card_div">
					<img class="card_div_img" src="../../../../assets/img/ver3.png" />
					<div style="">
						<div class="card_div_title">{{$t('pcbaMade.banner.content3.title')}}</div>
						<div class="card_div_text">{{$t('pcbaMade.banner.content3.text')}}</div>
					</div>
				</div>
				
				<div class="card_div">
					<img class="card_div_img" src="../../../../assets/img/ver4.png" />
					<div style="">
						<div class="card_div_title">{{$t('pcbaMade.banner.content4.title')}}</div>
						<div class="card_div_text">{{$t('pcbaMade.banner.content4.text')}}</div>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="windowWidth<=1199">
			<div class="banner2" :style="'background-image:' + `url(${require('@/assets/img/patch-weld-bg.jpg')})`">
				<div class="ba_title">{{$t('pcbaMade.banner.title')}}</div>
				<div class="ba_text">{{$t('pcbaMade.banner.text')}}</div>
				<div class="ba_bannerHot">
					<span class="ba_bh_lable">{{$t('pcbaMade.banner.text2')}}</span>
					<span class="ba_bh_hot">{{$t('pcbaMade.banner.label1')}}</span>
					<span class="ba_bh_hot">{{$t('pcbaMade.banner.label2')}}</span>
					<span class="ba_bh_hot">{{$t('pcbaMade.banner.label3')}}</span>
				</div>
				<!-- <div class="ba_jump">{{$t('pcbaMade.banner.button')}}</div> -->
			</div>
			<div class="card2">
				<div class="card_div">
					<img class="card_div_img" src="../../../../assets/img/ver1.png" />
					<div style="">
						<div class="card_div_title">{{$t('pcbaMade.banner.content1.title')}}</div>
						<div class="card_div_text">{{$t('pcbaMade.banner.content1.text')}}</div>
					</div>
				</div>
				
				<div class="card_div">
					<img class="card_div_img" src="../../../../assets/img/ver2.png" />
					<div style="">
						<div class="card_div_title">{{$t('pcbaMade.banner.content2.title')}}</div>
						<div class="card_div_text">{{$t('pcbaMade.banner.content2.text')}}</div>
					</div>
				</div>
				
				<div class="card_div">
					<img class="card_div_img" src="../../../../assets/img/ver3.png" />
					<div style="">
						<div class="card_div_title">{{$t('pcbaMade.banner.content3.title')}}</div>
						<div class="card_div_text">{{$t('pcbaMade.banner.content3.text')}}</div>
					</div>
				</div>
				
				<div class="card_div">
					<img class="card_div_img" src="../../../../assets/img/ver4.png" />
					<div style="">
						<div class="card_div_title">{{$t('pcbaMade.banner.content4.title')}}</div>
						<div class="card_div_text">{{$t('pcbaMade.banner.content4.text')}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {

			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	@font-face {
		font-family: 'zhenyan';
		src: url('../../../../assets/RuiZiZhenYanTiMianFeiShangYong-2.ttf') format('truetype');
	}
	
	@media only screen and (min-width:1920px) {
		.banner {
			padding: 40px 200px;
		
			.ba_title {
				font-size: 40px;
				color: #f7d9a3;
				font-family: zhenyan;
			}
		
			.ba_text {
				font-size: 18px;
				line-height: 28px;
				color: #f7d9a3;
				width: 530px;
			}
		
			.ba_bannerHot {
				display: inline-block;
				margin-top: 22px;
				min-width: 470px;
				height: 43px;
				background-color: #292929;
				border-radius: 6px;
				border: solid 2px #f7d9a3;
				overflow: hidden;
		
				.ba_bh_lable {
					line-height: 43px;
					display: inline-block;
					background: #f7d9a3;
					font-size: 18px;
					color: #2c2c2c;
					padding: 0 12px;
					margin-right: 16px;
					font-weight: bold;
				}
		
				.ba_bh_hot {
					background: url('../../../../assets/img/check-icon1.png') no-repeat left center;
					padding-left: 28px;
					color: #f7d9a3;
					font-size: 18px;
					margin-right: 20px;
					font-weight: bold;
				}
			}
		
			.ba_jump {
				display: block;
				width: 178px;
				height: 49px;
				line-height: 49px;
				border: 1px solid #fff;
				text-align: center;
				margin-top: 30px;
				font-size: 18px;
				border-radius: 25px;
				margin-left: 10px;
				color: #fff;
				transition: all .3s;
				cursor: pointer;
			}
			
			.ba_jump:hover{
				color: #f7d9a3;
				border: 1px solid #f7d9a3;
			}
		}
		
		
		.card{
			padding: 20px 200px;
			width: 100%;
			display: flex;
			box-sizing: border-box;
			
			.card_div{
				width: 24%;
				margin-right: 1%;
				padding: 20px;
				box-shadow: 1px 4px 18px 0px rgb(128 128 128 / 20%);
				transition: 0.3s;
				display: flex;
				
				.card_div_img{
					width: 96px;
					height: 96px;
				}
				
				.card_div_title{
					font-size: 18px;
					color: #333333;
				}
				
				.card_div_text{
					font-size: 14px;
					color: #757575;
					margin-top: 8px;
				}
			}
			
			.card_div:hover{
				transform: translateY(-5px);
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.banner {
			padding: 40px 200px;
		
			.ba_title {
				font-size: 40px;
				color: #f7d9a3;
				font-family: zhenyan;
			}
		
			.ba_text {
				font-size: 18px;
				line-height: 28px;
				color: #f7d9a3;
				width: 530px;
			}
		
			.ba_bannerHot {
				display: inline-block;
				margin-top: 22px;
				min-width: 470px;
				height: 43px;
				background-color: #292929;
				border-radius: 6px;
				border: solid 2px #f7d9a3;
				overflow: hidden;
		
				.ba_bh_lable {
					line-height: 43px;
					display: inline-block;
					background: #f7d9a3;
					font-size: 18px;
					color: #2c2c2c;
					padding: 0 12px;
					margin-right: 16px;
					font-weight: bold;
				}
		
				.ba_bh_hot {
					background: url('../../../../assets/img/check-icon1.png') no-repeat left center;
					padding-left: 28px;
					color: #f7d9a3;
					font-size: 18px;
					margin-right: 20px;
					font-weight: bold;
				}
			}
		
			.ba_jump {
				display: block;
				width: 178px;
				height: 49px;
				line-height: 49px;
				border: 1px solid #fff;
				text-align: center;
				margin-top: 30px;
				font-size: 18px;
				border-radius: 25px;
				margin-left: 10px;
				color: #fff;
				transition: all .3s;
				cursor: pointer;
			}
			
			.ba_jump:hover{
				color: #f7d9a3;
				border: 1px solid #f7d9a3;
			}
		}
		
		
		.card{
			padding: 20px 200px;
			width: 100%;
			display: flex;
			box-sizing: border-box;
			
			.card_div{
				width: 24%;
				margin-right: 1%;
				padding: 20px;
				box-shadow: 1px 4px 18px 0px rgb(128 128 128 / 20%);
				transition: 0.3s;
				display: flex;
				
				.card_div_img{
					width: 96px;
					height: 96px;
				}
				
				.card_div_title{
					font-size: 18px;
					color: #333333;
				}
				
				.card_div_text{
					font-size: 14px;
					color: #757575;
					margin-top: 8px;
				}
			}
			
			.card_div:hover{
				transform: translateY(-5px);
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.banner {
			padding: 40px 140px;
		
			.ba_title {
				font-size: 40px;
				color: #f7d9a3;
				font-family: zhenyan;
			}
		
			.ba_text {
				font-size: 18px;
				line-height: 28px;
				color: #f7d9a3;
				width: 530px;
			}
		
			.ba_bannerHot {
				display: inline-block;
				margin-top: 22px;
				min-width: 470px;
				height: 43px;
				background-color: #292929;
				border-radius: 6px;
				border: solid 2px #f7d9a3;
				overflow: hidden;
		
				.ba_bh_lable {
					line-height: 43px;
					display: inline-block;
					background: #f7d9a3;
					font-size: 18px;
					color: #2c2c2c;
					padding: 0 12px;
					margin-right: 16px;
					font-weight: bold;
				}
		
				.ba_bh_hot {
					background: url('../../../../assets/img/check-icon1.png') no-repeat left center;
					padding-left: 28px;
					color: #f7d9a3;
					font-size: 18px;
					margin-right: 20px;
					font-weight: bold;
				}
			}
		
			.ba_jump {
				display: block;
				width: 178px;
				height: 49px;
				line-height: 49px;
				border: 1px solid #fff;
				text-align: center;
				margin-top: 30px;
				font-size: 18px;
				border-radius: 25px;
				margin-left: 10px;
				color: #fff;
				transition: all .3s;
				cursor: pointer;
			}
			
			.ba_jump:hover{
				color: #f7d9a3;
				border: 1px solid #f7d9a3;
			}
		}
		
		
		.card{
			padding: 20px 140px;
			width: 100%;
			display: flex;
			box-sizing: border-box;
			
			.card_div{
				width: 24%;
				margin-right: 1%;
				padding: 20px;
				box-shadow: 1px 4px 18px 0px rgb(128 128 128 / 20%);
				transition: 0.3s;
				display: flex;
				
				.card_div_img{
					width: 96px;
					height: 96px;
				}
				
				.card_div_title{
					font-size: 18px;
					color: #333333;
				}
				
				.card_div_text{
					font-size: 14px;
					color: #757575;
					margin-top: 8px;
				}
			}
			
			.card_div:hover{
				transform: translateY(-5px);
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.banner {
			padding: 40px 30px;
		
			.ba_title {
				font-size: 40px;
				color: #f7d9a3;
				font-family: zhenyan;
			}
		
			.ba_text {
				font-size: 18px;
				line-height: 28px;
				color: #f7d9a3;
				width: 530px;
			}
		
			.ba_bannerHot {
				display: inline-block;
				margin-top: 22px;
				min-width: 470px;
				height: 43px;
				background-color: #292929;
				border-radius: 6px;
				border: solid 2px #f7d9a3;
				overflow: hidden;
		
				.ba_bh_lable {
					line-height: 43px;
					display: inline-block;
					background: #f7d9a3;
					font-size: 18px;
					color: #2c2c2c;
					padding: 0 12px;
					margin-right: 16px;
					font-weight: bold;
				}
		
				.ba_bh_hot {
					background: url('../../../../assets/img/check-icon1.png') no-repeat left center;
					padding-left: 28px;
					color: #f7d9a3;
					font-size: 18px;
					margin-right: 20px;
					font-weight: bold;
				}
			}
		
			.ba_jump {
				display: block;
				width: 178px;
				height: 49px;
				line-height: 49px;
				border: 1px solid #fff;
				text-align: center;
				margin-top: 30px;
				font-size: 18px;
				border-radius: 25px;
				margin-left: 10px;
				color: #fff;
				transition: all .3s;
				cursor: pointer;
			}
			
			.ba_jump:hover{
				color: #f7d9a3;
				border: 1px solid #f7d9a3;
			}
		}
		
		
		.card{
			padding: 20px 30px;
			width: 100%;
			display: flex;
			box-sizing: border-box;
			
			.card_div{
				width: 24%;
				margin-right: 1%;
				padding: 20px;
				box-shadow: 1px 4px 18px 0px rgb(128 128 128 / 20%);
				transition: 0.3s;
				display: flex;
				
				.card_div_img{
					width: 96px;
					height: 96px;
				}
				
				.card_div_title{
					font-size: 18px;
					color: #333333;
				}
				
				.card_div_text{
					font-size: 14px;
					color: #757575;
					margin-top: 8px;
				}
			}
			
			.card_div:hover{
				transform: translateY(-5px);
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.banner2 {
			padding: 100px 30px 40px 30px;
		
			.ba_title {
				font-size: 28px;
				color: #f7d9a3;
				font-family: zhenyan;
			}
		
			.ba_text {
				font-size: 16px;
				line-height: 20px;
				color: #f7d9a3;
				width: 530px;
			}
		
			.ba_bannerHot {
				display: inline-block;
				margin-top: 10px;
				min-width: 470px;
				background-color: #292929;
				border-radius: 6px;
				border: solid 2px #f7d9a3;
				overflow: hidden;
		
				.ba_bh_lable {
					display: inline-block;
					background: #f7d9a3;
					font-size: 16px;
					color: #2c2c2c;
					padding: 0 12px;
					margin-right: 16px;
					font-weight: bold;
				}
		
				.ba_bh_hot {
					background: url('../../../../assets/img/check-icon1.png') no-repeat left center;
					padding-left: 28px;
					color: #f7d9a3;
					font-size: 16px;
					margin-right: 20px;
					font-weight: bold;
				}
			}
		
			.ba_jump {
				display: block;
				width: 178px;
				height: 49px;
				line-height: 49px;
				border: 1px solid #fff;
				text-align: center;
				margin-top: 30px;
				font-size: 18px;
				border-radius: 25px;
				margin-left: 10px;
				color: #fff;
				transition: all .3s;
				cursor: pointer;
			}
			
			.ba_jump:hover{
				color: #f7d9a3;
				border: 1px solid #f7d9a3;
			}
		}
		
		
		.card2{
			padding: 20px 30px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			box-sizing: border-box;
			
			.card_div{
				width: 48%;
				margin-right: 1%;
				padding: 20px;
				box-shadow: 1px 4px 18px 0px rgb(128 128 128 / 20%);
				transition: 0.3s;
				display: flex;
				margin-bottom: 10px;
				
				.card_div_img{
					width: 96px;
					height: 96px;
				}
				
				.card_div_title{
					font-size: 18px;
					color: #333333;
				}
				
				.card_div_text{
					font-size: 14px;
					color: #757575;
					margin-top: 8px;
				}
			}
			
			.card_div:hover{
				transform: translateY(-5px);
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.banner2 {
			padding: 100px 30px 40px 30px;
		
			.ba_title {
				font-size: 28px;
				color: #f7d9a3;
				font-family: zhenyan;
			}
		
			.ba_text {
				font-size: 16px;
				line-height: 20px;
				color: #f7d9a3;
			}
		
			.ba_bannerHot {
				display: inline-block;
				margin-top: 10px;
				background-color: #292929;
				border-radius: 6px;
				border: solid 2px #f7d9a3;
				overflow: hidden;
		
				.ba_bh_lable {
					display: inline-block;
					background: #f7d9a3;
					font-size: 16px;
					color: #2c2c2c;
					padding: 0 12px;
					margin-right: 16px;
					font-weight: bold;
				}
		
				.ba_bh_hot {
					background: url('../../../../assets/img/check-icon1.png') no-repeat left center;
					padding-left: 28px;
					color: #f7d9a3;
					font-size: 16px;
					margin-right: 20px;
					font-weight: bold;
				}
			}
		
			.ba_jump {
				display: block;
				width: 178px;
				height: 49px;
				line-height: 49px;
				border: 1px solid #fff;
				text-align: center;
				margin-top: 30px;
				font-size: 18px;
				border-radius: 25px;
				margin-left: 10px;
				color: #fff;
				transition: all .3s;
				cursor: pointer;
			}
			
			.ba_jump:hover{
				color: #f7d9a3;
				border: 1px solid #f7d9a3;
			}
		}
		
		
		.card2{
			padding: 20px 30px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			box-sizing: border-box;
			
			.card_div{
				width: 100%;
				margin-right: 1%;
				padding: 20px;
				box-shadow: 1px 4px 18px 0px rgb(128 128 128 / 20%);
				transition: 0.3s;
				display: flex;
				margin-bottom: 10px;
				
				.card_div_img{
					width: 96px;
					height: 96px;
				}
				
				.card_div_title{
					font-size: 18px;
					color: #333333;
				}
				
				.card_div_text{
					font-size: 14px;
					color: #757575;
					margin-top: 8px;
				}
			}
			
			.card_div:hover{
				transform: translateY(-5px);
			}
		}
	}
</style>
