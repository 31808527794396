<template>
	<div>
		<div class="pcbaAssemble" v-if="windowWidth>=1200">
			<div class="pa_titleDiv">
				<div class="pa_td_title">{{$t('pcbaMade.pcbaAssemble.title')}}</div>
				<div class="pa_td_text">{{$t('pcbaMade.pcbaAssemble.text')}}
				</div>
			</div>

			<div class="pa_tabsDiv">
				<div class="pa_td_div">
					<div v-for="(item,index) in $t('pcbaMade.pcbaAssemble.tabs')" :key="index" class="pa_td_di_name"
						:style="tabsIndex==index ? 'color: #fff;background: #d1102d;' : ''"
						@mouseover="tabsMouseover(index)">{{item.name}}</div>
				</div>
				<div class="pa_td_right">
					<div class="pa_td_ri_text">{{$t('pcbaMade.pcbaAssemble.rightTitle')}}</div>
				</div>
				<div class="pa_td_rightBg"></div>
			</div>

			<div class="pa_contentDiv">
				<div class="pa_cd_left" v-if="tabsIndex==0">
					<div class="pa_cd_le_div">
						<div class="pa_cd_le_di_img">
							<el-image style="width: 100%;height: 100%;" :src="$t('pcbaMade.pcbaAssemble.content1.img')" :fit="'cover'"></el-image>
						</div>
						<div class="pa_cd_le_di_content">
							<div class="pa_cd_le_di_ct_title">{{$t('pcbaMade.pcbaAssemble.content1.title')}}</div>
							<div class="pa_cd_le_di_ct_text">{{$t('pcbaMade.pcbaAssemble.content1.text')}}
							</div>

							<div class="pa_cd_le_di_ct_multiRules">
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content1.label1')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content1.label2')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content1.label3')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content1.label4')}}</div>
							</div>

							<!-- <div class="pa_cd_le_di_ct_jump">
								<span class="pa_cd_le_di_ct_jp_price">
									<em class="em">￥</em>
									400
									<i class="i">{{$t('pcbaMade.pcbaAssemble.content1.text2')}}</i>
								</span>
								<span class="pa_cd_le_di_ct_jp_text">{{$t('pcbaMade.pcbaAssemble.content1.button')}}</span>
							</div> -->
						</div>
					</div>
				</div>

				<div class="pa_cd_left" v-if="tabsIndex==1">
					<div class="pa_cd_le_div">
						<div class="pa_cd_le_di_img">
							<el-image style="width: 100%;height: 100%;" :src="$t('pcbaMade.pcbaAssemble.content2.img')" :fit="'cover'"></el-image>
						</div>
						<div class="pa_cd_le_di_content">
							<div class="pa_cd_le_di_ct_title">{{$t('pcbaMade.pcbaAssemble.content2.title')}}</div>
							<div class="pa_cd_le_di_ct_text">{{$t('pcbaMade.pcbaAssemble.content2.text')}}
							</div>

							<div class="pa_cd_le_di_ct_multiRules">
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content2.label1')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content2.label2')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content2.label3')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content2.label4')}}</div>
							</div>

							<!-- <div class="pa_cd_le_di_ct_jump">
								<span class="pa_cd_le_di_ct_jp_price">
									<em class="em">￥</em>
									30
									<i class="i">{{$t('pcbaMade.pcbaAssemble.content2.text2')}}</i>
								</span>
								<span class="pa_cd_le_di_ct_jp_text">{{$t('pcbaMade.pcbaAssemble.content2.button')}}</span>
							</div> -->
						</div>
					</div>
				</div>

				<div class="pa_cd_left" v-if="tabsIndex==2">
					<div class="pa_cd_le_div">
						<div class="pa_cd_le_di_img">
							<el-image style="width: 100%;height: 100%;" :src="$t('pcbaMade.pcbaAssemble.content3.img')" :fit="'cover'"></el-image>
						</div>
						<div class="pa_cd_le_di_content">
							<div class="pa_cd_le_di_ct_title">{{$t('pcbaMade.pcbaAssemble.content3.title')}}</div>
							<div class="pa_cd_le_di_ct_text">{{$t('pcbaMade.pcbaAssemble.content3.text')}}</div>

							<div class="pa_cd_le_di_ct_multiRules">
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content3.label1')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content3.label2')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content3.label3')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content3.label4')}}</div>
							</div>

							<!-- <div class="pa_cd_le_di_ct_jump">
								<span class="pa_cd_le_di_ct_jp_text">{{$t('pcbaMade.pcbaAssemble.content3.button')}}</span>
							</div> -->
						</div>
					</div>
				</div>

				<div class="pa_cd_left" v-if="tabsIndex==3">
					<div class="pa_cd_le_div">
						<div class="pa_cd_le_di_img">
							<el-image style="width: 100%;height: 100%;" :src="$t('pcbaMade.pcbaAssemble.content4.img')" :fit="'cover'"></el-image>
						</div>
						<div class="pa_cd_le_di_content">
							<div class="pa_cd_le_di_ct_title">{{$t('pcbaMade.pcbaAssemble.content4.title')}}</div>
							<div class="pa_cd_le_di_ct_text">{{$t('pcbaMade.pcbaAssemble.content4.text')}}
							</div>

							<div class="pa_cd_le_di_ct_multiRules">
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content4.label1')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content4.label2')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content4.label3')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content4.label4')}}</div>
							</div>

							<!-- <div class="pa_cd_le_di_ct_jump">
								<span class="pa_cd_le_di_ct_jp_text">{{$t('pcbaMade.pcbaAssemble.content4.button')}}</span>
							</div> -->
						</div>
					</div>
				</div>

				<div class="pa_cd_right">
					<div class="pa_cd_ri_div">
						<img class="pa_cd_ri_di_img" :src="$t('pcbaMade.pcbaAssemble.rightContent.img')" />

						<div class="pa_cd_ri_di_certification">
							<span class="pa_cd_ri_di_cer_name">{{$t('pcbaMade.pcbaAssemble.rightContent.title1')}}</span>
							<span class="pa_cd_ri_di_cer_val">{{$t('pcbaMade.pcbaAssemble.rightContent.text1')}}</span>
						</div>

						<div class="pa_cd_ri_di_certification">
							<span class="pa_cd_ri_di_cer_name">{{$t('pcbaMade.pcbaAssemble.rightContent.title2')}}</span>
							<span class="pa_cd_ri_di_cer_val">{{$t('pcbaMade.pcbaAssemble.rightContent.text2')}}</span>
						</div>

						<div class="pa_cd_ri_di_certification">
							<span class="pa_cd_ri_di_cer_name">{{$t('pcbaMade.pcbaAssemble.rightContent.title3')}}</span>
							<span class="pa_cd_ri_di_cer_val">{{$t('pcbaMade.pcbaAssemble.rightContent.text3')}}</span>
						</div>

						<div class="pa_cd_ri_di_certification">
							<span class="pa_cd_ri_di_cer_name">{{$t('pcbaMade.pcbaAssemble.rightContent.title4')}}</span>
							<span class="pa_cd_ri_di_cer_val">{{$t('pcbaMade.pcbaAssemble.rightContent.text4')}}</span>
						</div>

						<div class="pa_cd_ri_di_tips">{{$t('pcbaMade.pcbaAssemble.rightContent.title5')}}：</div>
						<div class="pa_cd_ri_di_tips2">{{$t('pcbaMade.pcbaAssemble.rightContent.text5')}}</div>
					</div>
				</div>
			</div>

		</div>
		
		<div class="pcbaAssemble2" v-if="windowWidth<=1199">
			<div class="pa_titleDiv">
				<div class="pa_td_title">{{$t('pcbaMade.pcbaAssemble.title')}}</div>
				<div class="pa_td_text">{{$t('pcbaMade.pcbaAssemble.text')}}
				</div>
			</div>
		
			<div class="pa_tabsDiv">
				<div class="pa_td_div">
					<div v-for="(item,index) in $t('pcbaMade.pcbaAssemble.tabs')" :key="index" class="pa_td_di_name"
						:style="tabsIndex==index ? 'color: #fff;background: #d1102d;' : ''"
						@mouseover="tabsMouseover(index)">{{item.name}}</div>
				</div>
			</div>
		
			<div class="pa_contentDiv">
				<div class="pa_cd_left" v-if="tabsIndex==0">
					<div class="pa_cd_le_div">
						<div class="pa_cd_le_di_img">
							<el-image style="width: 100%;height: 100%;" :src="$t('pcbaMade.pcbaAssemble.content1.img')" :fit="'cover'"></el-image>
						</div>
						<div class="pa_cd_le_di_content">
							<div class="pa_cd_le_di_ct_title">{{$t('pcbaMade.pcbaAssemble.content1.title')}}</div>
							<div class="pa_cd_le_di_ct_text">{{$t('pcbaMade.pcbaAssemble.content1.text')}}
							</div>
		
							<div class="pa_cd_le_di_ct_multiRules">
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content1.label1')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content1.label2')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content1.label3')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content1.label4')}}</div>
							</div>
		
							<!-- <div class="pa_cd_le_di_ct_jump">
								<span class="pa_cd_le_di_ct_jp_price">
									<em class="em">￥</em>
									400
									<i class="i">{{$t('pcbaMade.pcbaAssemble.content1.text2')}}</i>
								</span>
								<span class="pa_cd_le_di_ct_jp_text">{{$t('pcbaMade.pcbaAssemble.content1.button')}}</span>
							</div> -->
						</div>
					</div>
				</div>
		
				<div class="pa_cd_left" v-if="tabsIndex==1">
					<div class="pa_cd_le_div">
						<div class="pa_cd_le_di_img">
							<el-image style="width: 100%;height: 100%;" :src="$t('pcbaMade.pcbaAssemble.content2.img')" :fit="'cover'"></el-image>
						</div>
						<div class="pa_cd_le_di_content">
							<div class="pa_cd_le_di_ct_title">{{$t('pcbaMade.pcbaAssemble.content2.title')}}</div>
							<div class="pa_cd_le_di_ct_text">{{$t('pcbaMade.pcbaAssemble.content2.text')}}
							</div>
		
							<div class="pa_cd_le_di_ct_multiRules">
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content2.label1')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content2.label2')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content2.label3')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content2.label4')}}</div>
							</div>
		
							<!-- <div class="pa_cd_le_di_ct_jump">
								<span class="pa_cd_le_di_ct_jp_price">
									<em class="em">￥</em>
									30
									<i class="i">{{$t('pcbaMade.pcbaAssemble.content2.text2')}}</i>
								</span>
								<span class="pa_cd_le_di_ct_jp_text">{{$t('pcbaMade.pcbaAssemble.content2.button')}}</span>
							</div> -->
						</div>
					</div>
				</div>
		
				<div class="pa_cd_left" v-if="tabsIndex==2">
					<div class="pa_cd_le_div">
						<div class="pa_cd_le_di_img">
							<el-image style="width: 100%;height: 100%;" :src="$t('pcbaMade.pcbaAssemble.content3.img')" :fit="'cover'"></el-image>
						</div>
						<div class="pa_cd_le_di_content">
							<div class="pa_cd_le_di_ct_title">{{$t('pcbaMade.pcbaAssemble.content3.title')}}</div>
							<div class="pa_cd_le_di_ct_text">{{$t('pcbaMade.pcbaAssemble.content3.text')}}</div>
		
							<div class="pa_cd_le_di_ct_multiRules">
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content3.label1')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content3.label2')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content3.label3')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content3.label4')}}</div>
							</div>
		
							<!-- <div class="pa_cd_le_di_ct_jump">
								<span class="pa_cd_le_di_ct_jp_text">{{$t('pcbaMade.pcbaAssemble.content3.button')}}</span>
							</div> -->
						</div>
					</div>
				</div>
		
				<div class="pa_cd_left" v-if="tabsIndex==3">
					<div class="pa_cd_le_div">
						<div class="pa_cd_le_di_img">
							<el-image style="width: 100%;height: 100%;" :src="$t('pcbaMade.pcbaAssemble.content4.img')" :fit="'cover'"></el-image>
						</div>
						<div class="pa_cd_le_di_content">
							<div class="pa_cd_le_di_ct_title">{{$t('pcbaMade.pcbaAssemble.content4.title')}}</div>
							<div class="pa_cd_le_di_ct_text">{{$t('pcbaMade.pcbaAssemble.content4.text')}}
							</div>
		
							<div class="pa_cd_le_di_ct_multiRules">
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content4.label1')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content4.label2')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content4.label3')}}</div>
								<div class="text">{{$t('pcbaMade.pcbaAssemble.content4.label4')}}</div>
							</div>
		
							<!-- <div class="pa_cd_le_di_ct_jump">
								<span class="pa_cd_le_di_ct_jp_text">{{$t('pcbaMade.pcbaAssemble.content4.button')}}</span>
							</div> -->
						</div>
					</div>
				</div>
		
				<div class="pa_cd_right">
					<div class="pa_cd_ri_div">
						<div>
							<img class="pa_cd_ri_di_img" :src="$t('pcbaMade.pcbaAssemble.rightContent.img')" />
							
							<div style="display: flex;justify-content: center;">
								<div>
									<div class="pa_cd_ri_di_certification">
										<span class="pa_cd_ri_di_cer_name">{{$t('pcbaMade.pcbaAssemble.rightContent.title1')}}</span>
										<span class="pa_cd_ri_di_cer_val">{{$t('pcbaMade.pcbaAssemble.rightContent.text1')}}</span>
									</div>
											
									<div class="pa_cd_ri_di_certification">
										<span class="pa_cd_ri_di_cer_name">{{$t('pcbaMade.pcbaAssemble.rightContent.title2')}}</span>
										<span class="pa_cd_ri_di_cer_val">{{$t('pcbaMade.pcbaAssemble.rightContent.text2')}}</span>
									</div>
											
									<div class="pa_cd_ri_di_certification">
										<span class="pa_cd_ri_di_cer_name">{{$t('pcbaMade.pcbaAssemble.rightContent.title3')}}</span>
										<span class="pa_cd_ri_di_cer_val">{{$t('pcbaMade.pcbaAssemble.rightContent.text3')}}</span>
									</div>
											
									<div class="pa_cd_ri_di_certification">
										<span class="pa_cd_ri_di_cer_name">{{$t('pcbaMade.pcbaAssemble.rightContent.title4')}}</span>
										<span class="pa_cd_ri_di_cer_val">{{$t('pcbaMade.pcbaAssemble.rightContent.text4')}}</span>
									</div>
								</div>
							</div>
									
							<div class="pa_cd_ri_di_tips">{{$t('pcbaMade.pcbaAssemble.rightContent.title5')}}：</div>
							<div class="pa_cd_ri_di_tips2">{{$t('pcbaMade.pcbaAssemble.rightContent.text5')}}</div>
						</div>
					</div>
				</div>
			</div>
		
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				tabs: [{
						name: "PCBA贴片贴装"
					},
					{
						name: "PCB打样/小批量"
					},
					{
						name: "BOM配单/元件器"
					},
					{
						name: "方案设计"
					}
				],
				tabsIndex: 0, //tabs下标
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//------------tabs切换--------------------
			tabsMouseover: function(index) {
				this.tabsIndex = index;
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.pcbaAssemble {
			width: 100%;
			padding: 40px 200px;
		
			.pa_titleDiv {
				text-align: center;
		
				.pa_td_title {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
		
				.pa_td_text {
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
		
			.pa_tabsDiv {
				display: flex;
				margin-top: 40px;
				align-items: center;
				position: relative;
		
				.pa_td_div {
					display: flex;
					width: 70%;
					z-index: 4;
		
					.pa_td_di_name {
						padding: 1vw 2vw;
						font-weight: bold;
						font-size: 0.6vw;
					}
				}
		
				.pa_td_right {
					width: 30%;
					text-align: right;
					z-index: 4;
		
					.pa_td_ri_text {
						font-weight: bold;
						font-size: 0.6vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
		
				.pa_td_rightBg {
					position: absolute;
					width: 30%;
					height: 540px;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
		
			.pa_contentDiv {
				display: flex;
				margin-top: 40px;
				z-index: 4;
		
				.pa_cd_left {
					width: 80%;
					padding: 2vw;
					overflow: hidden;
					position: relative;
					background: #f5f5f5;
		
					.pa_cd_le_div {
						z-index: 6;
						width: 100%;
						display: flex;
		
						.pa_cd_le_di_img {
							width: 475px;
							height: 300px;
							float: left;
						}
		
						.pa_cd_le_di_content {
							padding-left: 1vw;
		
							.pa_cd_le_di_ct_title {
								font-weight: bold;
								font-size: 22px;
								color: #333333;
							}
		
							.pa_cd_le_di_ct_text {
								margin-top: 10px;
								font-size: 16px;
								color: #757575;
							}
		
							.pa_cd_le_di_ct_multiRules {
								padding-top: 20px;
		
								.text {
									font-size: 16px;
									color: #333333;
									background: url('../../../../assets/img/special.png') no-repeat 0 1px;
									padding-left: 36px;
									line-height: 24px;
									margin-bottom: 6px;
								}
							}
		
							.pa_cd_le_di_ct_jump {
								display: block;
								float: left;
								height: 55px;
								line-height: 55px;
								text-align: center;
								box-shadow: 2px 5px 15px 0px rgb(245 80 53 / 15%);
								border-radius: 28px;
								border: solid 1px #d1102d;
								margin-top: 20px;
								background: #d1102d;
								overflow: hidden;
								background-size: 300% 100%;
		
								.pa_cd_le_di_ct_jp_price {
									display: block;
									width: 150px;
									float: left;
									height: 53px;
									line-height: 53px;
									font-size: 38px;
									color: #d1102d;
									text-align: center;
									background: #fff;
									border-radius: 53px 0 0 53px;
									font-weight: bold;
		
									.em {
										display: inline-block;
										font-size: 18px;
										font-weight: normal;
									}
		
									.i {
										font-style: normal;
										display: inline-block;
										font-size: 18px;
										margin-left: -10px;
										font-weight: normal;
									}
								}
		
								.pa_cd_le_di_ct_jp_text {
									float: left;
									display: block;
									padding: 0 40px;
									height: 53px;
									line-height: 53px;
									border-radius: 0 55px 55px 0;
									text-align: center;
									color: #fff;
									background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.5) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(#ff8b10, #ff8b10);
									background-blend-mode: normal, normal;
									font-size: 20px;
								}
							}
						}
					}
				}
		
				.pa_cd_right {
					width: 20%;
					padding: 0 2vw;
		
					.pa_cd_ri_div {
						position: relative;
		
						.pa_cd_ri_di_img {
							width: 190px;
							height: 47px;
						}
		
						.pa_cd_ri_di_certification {
							width: 190px;
							height: 35px;
							line-height: 35px;
							border-radius: 6px;
							border: solid 1px #ffffff;
							overflow: hidden;
							font-size: 14px;
							margin-bottom: 10px;
		
							.pa_cd_ri_di_cer_name {
								color: #d1102d;
								width: 83px;
								height: 35px;
								display: block;
								float: left;
								text-align: center;
								background-color: #f5f5f5;
							}
		
							.pa_cd_ri_di_cer_val {
								display: block;
								float: left;
								padding-left: 10px;
								color: #fff;
								width: 105px;
							}
						}
		
						.pa_cd_ri_di_tips {
							font-size: 14px;
							padding-top: 20px;
							color: #fff;
						}
		
						.pa_cd_ri_di_tips2 {
							font-size: 14px;
							color: #fff;
							line-height: 30px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.pcbaAssemble {
			width: 100%;
			padding: 40px 200px;
		
			.pa_titleDiv {
				text-align: center;
		
				.pa_td_title {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
		
				.pa_td_text {
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
		
			.pa_tabsDiv {
				display: flex;
				margin-top: 40px;
				align-items: center;
				position: relative;
		
				.pa_td_div {
					display: flex;
					width: 70%;
					z-index: 4;
		
					.pa_td_di_name {
						padding: 1vw 2vw;
						font-weight: bold;
						font-size: 0.6vw;
					}
				}
		
				.pa_td_right {
					width: 30%;
					text-align: right;
					z-index: 4;
		
					.pa_td_ri_text {
						font-weight: bold;
						font-size: 0.6vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
		
				.pa_td_rightBg {
					position: absolute;
					width: 30%;
					height: 540px;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
		
			.pa_contentDiv {
				display: flex;
				margin-top: 40px;
				z-index: 4;
		
				.pa_cd_left {
					width: 80%;
					padding: 2vw;
					overflow: hidden;
					position: relative;
					background: #f5f5f5;
		
					.pa_cd_le_div {
						z-index: 6;
						width: 100%;
						display: flex;
		
						.pa_cd_le_di_img {
							width: 475px;
							height: 300px;
							float: left;
						}
		
						.pa_cd_le_di_content {
							padding-left: 1vw;
		
							.pa_cd_le_di_ct_title {
								font-weight: bold;
								font-size: 22px;
								color: #333333;
							}
		
							.pa_cd_le_di_ct_text {
								margin-top: 10px;
								font-size: 16px;
								color: #757575;
							}
		
							.pa_cd_le_di_ct_multiRules {
								padding-top: 20px;
		
								.text {
									font-size: 16px;
									color: #333333;
									background: url('../../../../assets/img/special.png') no-repeat 0 1px;
									padding-left: 36px;
									line-height: 24px;
									margin-bottom: 6px;
								}
							}
		
							.pa_cd_le_di_ct_jump {
								display: block;
								float: left;
								height: 55px;
								line-height: 55px;
								text-align: center;
								box-shadow: 2px 5px 15px 0px rgb(245 80 53 / 15%);
								border-radius: 28px;
								border: solid 1px #d1102d;
								margin-top: 20px;
								background: #d1102d;
								overflow: hidden;
								background-size: 300% 100%;
		
								.pa_cd_le_di_ct_jp_price {
									display: block;
									width: 150px;
									float: left;
									height: 53px;
									line-height: 53px;
									font-size: 38px;
									color: #d1102d;
									text-align: center;
									background: #fff;
									border-radius: 53px 0 0 53px;
									font-weight: bold;
		
									.em {
										display: inline-block;
										font-size: 18px;
										font-weight: normal;
									}
		
									.i {
										font-style: normal;
										display: inline-block;
										font-size: 18px;
										margin-left: -10px;
										font-weight: normal;
									}
								}
		
								.pa_cd_le_di_ct_jp_text {
									float: left;
									display: block;
									padding: 0 40px;
									height: 53px;
									line-height: 53px;
									border-radius: 0 55px 55px 0;
									text-align: center;
									color: #fff;
									background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.5) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(#ff8b10, #ff8b10);
									background-blend-mode: normal, normal;
									font-size: 20px;
								}
							}
						}
					}
				}
		
				.pa_cd_right {
					width: 20%;
					padding: 0 2vw;
		
					.pa_cd_ri_div {
						position: relative;
		
						.pa_cd_ri_di_img {
							width: 190px;
							height: 47px;
						}
		
						.pa_cd_ri_di_certification {
							width: 190px;
							height: 35px;
							line-height: 35px;
							border-radius: 6px;
							border: solid 1px #ffffff;
							overflow: hidden;
							font-size: 14px;
							margin-bottom: 10px;
		
							.pa_cd_ri_di_cer_name {
								color: #d1102d;
								width: 83px;
								height: 35px;
								display: block;
								float: left;
								text-align: center;
								background-color: #f5f5f5;
							}
		
							.pa_cd_ri_di_cer_val {
								display: block;
								float: left;
								padding-left: 10px;
								color: #fff;
								width: 105px;
							}
						}
		
						.pa_cd_ri_di_tips {
							font-size: 14px;
							padding-top: 20px;
							color: #fff;
						}
		
						.pa_cd_ri_di_tips2 {
							font-size: 14px;
							color: #fff;
							line-height: 30px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.pcbaAssemble {
			width: 100%;
			padding: 40px 140px;
		
			.pa_titleDiv {
				text-align: center;
		
				.pa_td_title {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
		
				.pa_td_text {
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
		
			.pa_tabsDiv {
				display: flex;
				margin-top: 40px;
				align-items: center;
				position: relative;
		
				.pa_td_div {
					display: flex;
					width: 70%;
					z-index: 4;
		
					.pa_td_di_name {
						padding: 1vw 2vw;
						font-weight: bold;
						font-size: 0.6vw;
					}
				}
		
				.pa_td_right {
					width: 30%;
					text-align: right;
					z-index: 4;
		
					.pa_td_ri_text {
						font-weight: bold;
						font-size: 0.6vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
		
				.pa_td_rightBg {
					position: absolute;
					width: 30%;
					height: 540px;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
		
			.pa_contentDiv {
				display: flex;
				margin-top: 40px;
				z-index: 4;
		
				.pa_cd_left {
					width: 80%;
					padding: 2vw;
					overflow: hidden;
					position: relative;
					background: #f5f5f5;
		
					.pa_cd_le_div {
						z-index: 6;
						width: 100%;
						display: flex;
		
						.pa_cd_le_di_img {
							width: 475px;
							height: 300px;
							float: left;
						}
		
						.pa_cd_le_di_content {
							padding-left: 1vw;
		
							.pa_cd_le_di_ct_title {
								font-weight: bold;
								font-size: 22px;
								color: #333333;
							}
		
							.pa_cd_le_di_ct_text {
								margin-top: 10px;
								font-size: 16px;
								color: #757575;
							}
		
							.pa_cd_le_di_ct_multiRules {
								padding-top: 20px;
		
								.text {
									font-size: 16px;
									color: #333333;
									background: url('../../../../assets/img/special.png') no-repeat 0 1px;
									padding-left: 36px;
									line-height: 24px;
									margin-bottom: 6px;
								}
							}
		
							.pa_cd_le_di_ct_jump {
								display: block;
								float: left;
								height: 55px;
								line-height: 55px;
								text-align: center;
								box-shadow: 2px 5px 15px 0px rgb(245 80 53 / 15%);
								border-radius: 28px;
								border: solid 1px #d1102d;
								margin-top: 20px;
								background: #d1102d;
								overflow: hidden;
								background-size: 300% 100%;
		
								.pa_cd_le_di_ct_jp_price {
									display: block;
									width: 150px;
									float: left;
									height: 53px;
									line-height: 53px;
									font-size: 38px;
									color: #d1102d;
									text-align: center;
									background: #fff;
									border-radius: 53px 0 0 53px;
									font-weight: bold;
		
									.em {
										display: inline-block;
										font-size: 18px;
										font-weight: normal;
									}
		
									.i {
										font-style: normal;
										display: inline-block;
										font-size: 18px;
										margin-left: -10px;
										font-weight: normal;
									}
								}
		
								.pa_cd_le_di_ct_jp_text {
									float: left;
									display: block;
									padding: 0 40px;
									height: 53px;
									line-height: 53px;
									border-radius: 0 55px 55px 0;
									text-align: center;
									color: #fff;
									background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.5) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(#ff8b10, #ff8b10);
									background-blend-mode: normal, normal;
									font-size: 20px;
								}
							}
						}
					}
				}
		
				.pa_cd_right {
					width: 20%;
					padding: 0 2vw;
		
					.pa_cd_ri_div {
						position: relative;
		
						.pa_cd_ri_di_img {
							width: 190px;
							height: 47px;
						}
		
						.pa_cd_ri_di_certification {
							width: 190px;
							height: 35px;
							line-height: 35px;
							border-radius: 6px;
							border: solid 1px #ffffff;
							overflow: hidden;
							font-size: 14px;
							margin-bottom: 10px;
		
							.pa_cd_ri_di_cer_name {
								color: #d1102d;
								width: 83px;
								height: 35px;
								display: block;
								float: left;
								text-align: center;
								background-color: #f5f5f5;
							}
		
							.pa_cd_ri_di_cer_val {
								display: block;
								float: left;
								padding-left: 10px;
								color: #fff;
								width: 105px;
							}
						}
		
						.pa_cd_ri_di_tips {
							font-size: 14px;
							padding-top: 20px;
							color: #fff;
						}
		
						.pa_cd_ri_di_tips2 {
							font-size: 14px;
							color: #fff;
							line-height: 30px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.pcbaAssemble {
			width: 100%;
			padding: 40px 30px;
		
			.pa_titleDiv {
				text-align: center;
		
				.pa_td_title {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
		
				.pa_td_text {
					font-size: 18px;
					color: #626262;
					line-height: 28px;
				}
			}
		
			.pa_tabsDiv {
				display: flex;
				margin-top: 40px;
				align-items: center;
				position: relative;
		
				.pa_td_div {
					display: flex;
					width: 70%;
					z-index: 4;
		
					.pa_td_di_name {
						padding: 1vw 2vw;
						font-weight: bold;
						font-size: 0.6vw;
					}
				}
		
				.pa_td_right {
					width: 30%;
					text-align: right;
					z-index: 4;
		
					.pa_td_ri_text {
						font-weight: bold;
						font-size: 0.6vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
		
				.pa_td_rightBg {
					position: absolute;
					width: 30%;
					height: 540px;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
		
			.pa_contentDiv {
				display: flex;
				margin-top: 40px;
				z-index: 4;
		
				.pa_cd_left {
					width: 80%;
					padding: 2vw;
					overflow: hidden;
					position: relative;
					background: #f5f5f5;
		
					.pa_cd_le_div {
						z-index: 6;
						width: 100%;
						display: flex;
		
						.pa_cd_le_di_img {
							width: 475px;
							height: 300px;
							float: left;
						}
		
						.pa_cd_le_di_content {
							padding-left: 1vw;
		
							.pa_cd_le_di_ct_title {
								font-weight: bold;
								font-size: 22px;
								color: #333333;
							}
		
							.pa_cd_le_di_ct_text {
								margin-top: 10px;
								font-size: 16px;
								color: #757575;
							}
		
							.pa_cd_le_di_ct_multiRules {
								padding-top: 20px;
		
								.text {
									font-size: 16px;
									color: #333333;
									background: url('../../../../assets/img/special.png') no-repeat 0 1px;
									padding-left: 36px;
									line-height: 24px;
									margin-bottom: 6px;
								}
							}
		
							.pa_cd_le_di_ct_jump {
								display: block;
								float: left;
								height: 55px;
								line-height: 55px;
								text-align: center;
								box-shadow: 2px 5px 15px 0px rgb(245 80 53 / 15%);
								border-radius: 28px;
								border: solid 1px #d1102d;
								margin-top: 20px;
								background: #d1102d;
								overflow: hidden;
								background-size: 300% 100%;
		
								.pa_cd_le_di_ct_jp_price {
									display: block;
									width: 150px;
									float: left;
									height: 53px;
									line-height: 53px;
									font-size: 38px;
									color: #d1102d;
									text-align: center;
									background: #fff;
									border-radius: 53px 0 0 53px;
									font-weight: bold;
		
									.em {
										display: inline-block;
										font-size: 18px;
										font-weight: normal;
									}
		
									.i {
										font-style: normal;
										display: inline-block;
										font-size: 18px;
										margin-left: -10px;
										font-weight: normal;
									}
								}
		
								.pa_cd_le_di_ct_jp_text {
									float: left;
									display: block;
									padding: 0 40px;
									height: 53px;
									line-height: 53px;
									border-radius: 0 55px 55px 0;
									text-align: center;
									color: #fff;
									background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.5) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(#ff8b10, #ff8b10);
									background-blend-mode: normal, normal;
									font-size: 20px;
								}
							}
						}
					}
				}
		
				.pa_cd_right {
					width: 20%;
					padding: 0 2vw;
		
					.pa_cd_ri_div {
						position: relative;
		
						.pa_cd_ri_di_img {
							width: 190px;
							height: 47px;
						}
		
						.pa_cd_ri_di_certification {
							width: 190px;
							height: 35px;
							line-height: 35px;
							border-radius: 6px;
							border: solid 1px #ffffff;
							overflow: hidden;
							font-size: 14px;
							margin-bottom: 10px;
		
							.pa_cd_ri_di_cer_name {
								color: #d1102d;
								width: 83px;
								height: 35px;
								display: block;
								float: left;
								text-align: center;
								background-color: #f5f5f5;
							}
		
							.pa_cd_ri_di_cer_val {
								display: block;
								float: left;
								padding-left: 10px;
								color: #fff;
								width: 105px;
							}
						}
		
						.pa_cd_ri_di_tips {
							font-size: 14px;
							padding-top: 20px;
							color: #fff;
						}
		
						.pa_cd_ri_di_tips2 {
							font-size: 14px;
							color: #fff;
							line-height: 30px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.pcbaAssemble2 {
			width: 100%;
			padding: 40px 30px;
		
			.pa_titleDiv {
				text-align: center;
		
				.pa_td_title {
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
		
				.pa_td_text {
					font-size: 16px;
					color: #626262;
					line-height: 28px;
				}
			}
		
			.pa_tabsDiv {
				display: flex;
				margin-top: 40px;
				align-items: center;
				justify-content: center;
				position: relative;
						
				.pa_td_div {
					display: flex;
					width: 70%;
					z-index: 4;
					flex-wrap: wrap;
					justify-content: center;
						
					.pa_td_di_name {
						padding: 10px;
						font-weight: bold;
						font-size: 12px;
						margin-bottom: 10px;
					}
				}
		
				.pa_td_right {
					width: 30%;
					text-align: right;
					z-index: 4;
		
					.pa_td_ri_text {
						font-weight: bold;
						font-size: 0.6vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
		
				.pa_td_rightBg {
					position: absolute;
					width: 30%;
					height: 540px;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
		
			.pa_contentDiv {
				margin-top: 40px;
				z-index: 4;
		
				.pa_cd_left {
					width: 100%;
					padding: 10px;
					overflow: hidden;
					position: relative;
					background: #f5f5f5;
		
					.pa_cd_le_div {
						z-index: 6;
						width: 100%;
		
						.pa_cd_le_di_img {
							padding-bottom: 10px;
						}
		
						.pa_cd_le_di_content {
							padding-left: 1vw;
		
							.pa_cd_le_di_ct_title {
								font-weight: bold;
								font-size: 22px;
								color: #333333;
							}
		
							.pa_cd_le_di_ct_text {
								margin-top: 10px;
								font-size: 16px;
								color: #757575;
							}
		
							.pa_cd_le_di_ct_multiRules {
								padding-top: 20px;
		
								.text {
									font-size: 16px;
									color: #333333;
									background: url('../../../../assets/img/special.png') no-repeat 0 1px;
									padding-left: 36px;
									line-height: 24px;
									margin-bottom: 6px;
								}
							}
		
							.pa_cd_le_di_ct_jump {
								display: block;
								float: left;
								height: 55px;
								line-height: 55px;
								text-align: center;
								box-shadow: 2px 5px 15px 0px rgb(245 80 53 / 15%);
								border-radius: 28px;
								border: solid 1px #d1102d;
								margin-top: 20px;
								background: #d1102d;
								overflow: hidden;
								background-size: 300% 100%;
		
								.pa_cd_le_di_ct_jp_price {
									display: block;
									width: 150px;
									float: left;
									height: 53px;
									line-height: 53px;
									font-size: 38px;
									color: #d1102d;
									text-align: center;
									background: #fff;
									border-radius: 53px 0 0 53px;
									font-weight: bold;
		
									.em {
										display: inline-block;
										font-size: 18px;
										font-weight: normal;
									}
		
									.i {
										font-style: normal;
										display: inline-block;
										font-size: 18px;
										margin-left: -10px;
										font-weight: normal;
									}
								}
		
								.pa_cd_le_di_ct_jp_text {
									float: left;
									display: block;
									padding: 0 40px;
									height: 53px;
									line-height: 53px;
									border-radius: 0 55px 55px 0;
									text-align: center;
									color: #fff;
									background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.5) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(#ff8b10, #ff8b10);
									background-blend-mode: normal, normal;
									font-size: 20px;
								}
							}
						}
					}
				}
		
				.pa_cd_right {
					width: 100%;
					background: #d1102d;
					padding: 10px;
					margin-top: 20px;
		
					.pa_cd_ri_div {
						position: relative;
						display: flex;
						justify-content: center;
						text-align: center;
		
						.pa_cd_ri_di_img {
							width: 190px;
							height: 47px;
							margin-bottom: 20px;
						}
		
						.pa_cd_ri_di_certification {
							width: 190px;
							height: 35px;
							line-height: 35px;
							border-radius: 6px;
							border: solid 1px #ffffff;
							overflow: hidden;
							font-size: 14px;
							margin-bottom: 10px;
		
							.pa_cd_ri_di_cer_name {
								color: #d1102d;
								width: 83px;
								height: 35px;
								display: block;
								float: left;
								text-align: center;
								background-color: #f5f5f5;
							}
		
							.pa_cd_ri_di_cer_val {
								display: block;
								float: left;
								padding-left: 10px;
								color: #fff;
								width: 105px;
							}
						}
		
						.pa_cd_ri_di_tips {
							font-size: 14px;
							padding-top: 20px;
							color: #fff;
						}
		
						.pa_cd_ri_di_tips2 {
							font-size: 14px;
							color: #fff;
							line-height: 30px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.pcbaAssemble2 {
			width: 100%;
			padding: 40px 30px;
		
			.pa_titleDiv {
				text-align: center;
		
				.pa_td_title {
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
		
				.pa_td_text {
					font-size: 16px;
					color: #626262;
					line-height: 28px;
				}
			}
		
			.pa_tabsDiv {
				display: flex;
				margin-top: 40px;
				align-items: center;
				justify-content: center;
				position: relative;
		
				.pa_td_div {
					display: flex;
					width: 70%;
					z-index: 4;
					flex-wrap: wrap;
					justify-content: center;
		
					.pa_td_di_name {
						padding: 10px;
						font-weight: bold;
						font-size: 12px;
						margin-bottom: 10px;
					}
				}
		
				.pa_td_right {
					width: 30%;
					text-align: right;
					z-index: 4;
		
					.pa_td_ri_text {
						font-weight: bold;
						font-size: 0.6vw;
						padding: 1vw 2vw;
						color: #fff;
					}
				}
		
				.pa_td_rightBg {
					position: absolute;
					width: 30%;
					height: 540px;
					background: #d1102d;
					right: 0;
					top: 0;
				}
			}
		
			.pa_contentDiv {
				margin-top: 40px;
				z-index: 4;
		
				.pa_cd_left {
					width: 100%;
					padding: 2vw;
					overflow: hidden;
					position: relative;
					background: #f5f5f5;
		
					.pa_cd_le_div {
						z-index: 6;
						width: 100%;
		
						.pa_cd_le_di_img {
							padding-bottom: 10px;
						}
		
						.pa_cd_le_di_content {
							padding-left: 10px;
		
							.pa_cd_le_di_ct_title {
								font-weight: bold;
								font-size: 21px;
								color: #333333;
							}
		
							.pa_cd_le_di_ct_text {
								margin-top: 10px;
								font-size: 16px;
								color: #757575;
							}
		
							.pa_cd_le_di_ct_multiRules {
								padding-top: 20px;
		
								.text {
									font-size: 16px;
									color: #333333;
									background: url('../../../../assets/img/special.png') no-repeat 0 1px;
									padding-left: 36px;
									line-height: 24px;
									margin-bottom: 6px;
								}
							}
		
							.pa_cd_le_di_ct_jump {
								display: block;
								float: left;
								height: 55px;
								line-height: 55px;
								text-align: center;
								box-shadow: 2px 5px 15px 0px rgb(245 80 53 / 15%);
								border-radius: 28px;
								border: solid 1px #d1102d;
								margin-top: 20px;
								background: #d1102d;
								overflow: hidden;
								background-size: 300% 100%;
		
								.pa_cd_le_di_ct_jp_price {
									display: block;
									width: 150px;
									float: left;
									height: 53px;
									line-height: 53px;
									font-size: 38px;
									color: #d1102d;
									text-align: center;
									background: #fff;
									border-radius: 53px 0 0 53px;
									font-weight: bold;
		
									.em {
										display: inline-block;
										font-size: 18px;
										font-weight: normal;
									}
		
									.i {
										font-style: normal;
										display: inline-block;
										font-size: 18px;
										margin-left: -10px;
										font-weight: normal;
									}
								}
		
								.pa_cd_le_di_ct_jp_text {
									float: left;
									display: block;
									padding: 0 40px;
									height: 53px;
									line-height: 53px;
									border-radius: 0 55px 55px 0;
									text-align: center;
									color: #fff;
									background-image: linear-gradient(249deg, rgba(241, 60, 58, 0.5) 0%, rgba(241, 60, 58, 0.9) 100%), linear-gradient(#ff8b10, #ff8b10);
									background-blend-mode: normal, normal;
									font-size: 20px;
								}
							}
						}
					}
				}
		
				.pa_cd_right {
					width: 100%;
					background: #d1102d;
					padding: 10px;
					margin-top: 20px;
		
					.pa_cd_ri_div {
						position: relative;
						display: flex;
						justify-content: center;
						text-align: center;
		
						.pa_cd_ri_di_img {
							width: 190px;
							height: 47px;
							margin-bottom: 20px;
						}
		
						.pa_cd_ri_di_certification {
							width: 190px;
							height: 35px;
							line-height: 35px;
							border-radius: 6px;
							border: solid 1px #ffffff;
							overflow: hidden;
							font-size: 14px;
							margin-bottom: 10px;
		
							.pa_cd_ri_di_cer_name {
								color: #d1102d;
								width: 83px;
								height: 35px;
								display: block;
								float: left;
								text-align: center;
								background-color: #f5f5f5;
							}
		
							.pa_cd_ri_di_cer_val {
								display: block;
								float: left;
								padding-left: 10px;
								color: #fff;
								width: 105px;
							}
						}
		
						.pa_cd_ri_di_tips {
							font-size: 14px;
							padding-top: 20px;
							color: #fff;
						}
		
						.pa_cd_ri_di_tips2 {
							font-size: 14px;
							color: #fff;
							line-height: 30px;
						}
					}
				}
			}
		}
	}
	
</style>
